exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-content-article-uid-tsx": () => import("./../../../src/pages/articles/{ContentArticle.uid}.tsx" /* webpackChunkName: "component---src-pages-articles-content-article-uid-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-content-page-uid-tsx": () => import("./../../../src/pages/{ContentPage.uid}.tsx" /* webpackChunkName: "component---src-pages-content-page-uid-tsx" */),
  "component---src-pages-gameguides-index-tsx": () => import("./../../../src/pages/gameguides/index.tsx" /* webpackChunkName: "component---src-pages-gameguides-index-tsx" */),
  "component---src-pages-gameguides-prismic-eegs-game-uid-tsx": () => import("./../../../src/pages/gameguides/{prismicEegsGame.uid}.tsx" /* webpackChunkName: "component---src-pages-gameguides-prismic-eegs-game-uid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

