import React from 'react';

import '@dsl/loop-next-fonts';
import { Base } from '@dsl/loop-next-react';
import '@dsl/loop-next-react/dist/index.css';

import DataProvider from './src/components/utils/DataProvider';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => <DataProvider>{element}</DataProvider>;

export const wrapPageElement = ({ element }) => <Base>{element}</Base>;
